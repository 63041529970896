import { styled, css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';

import type { styledComponentInterface } from './types';

// z-index is reflected by popup z-index
// https://bitbucket.org/atlassian/atlassian-frontend/src/3c51f15f563a4aad8e0573396cc5b8203491c5c2/packages/design-system/theme/src/constants.tsx#lines-44
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LiveSearchWrapper = styled.div({
	padding: token('space.025'),
	width: '100%',
	maxWidth: '100%',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		color: token('color.text'),
		float: 'left',
		marginLeft: token('space.100'),
		backgroundColor: token('color.background.neutral'),
		height: '40px',
		display: 'flex',
		alignItems: 'center',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchMacro = styled.div<styledComponentInterface>(
	{
		font: token('font.body'),
		display: 'inline-block',
		flexGrow: 1,
		maxWidth: '100%',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.isLarge ? SearchMacroLarge : SearchMacroMedium),
);

const SearchMacroMedium = css({
	width: '393px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.search-macro-query': {
		float: 'left',
		width: 'calc(100% - 110px)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		input: {
			maxWidth: 'none',
		},
	},
});

const SearchMacroLarge = css({
	width: '600px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.search-macro-query': {
		position: 'relative',
		float: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':focus-within': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.iconWrapper': {
				display: 'none',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			input: {
				padding: `${token('space.025')} ${token('space.100')} ${token('space.025')} ${token('space.100')}`,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.iconWrapper': {
			position: 'absolute',
			top: token('space.100'),
			left: '9px',
			color: token('color.border.bold'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		input: {
			padding: `${token('space.025')} ${token('space.100')} ${token('space.025')} ${token('space.400')}`,
		},
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InputWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultIconWrapper = styled.div<{ contentType: string }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '3px',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultNormalWrapper = styled.div({
	minHeight: '23px',
	padding: `${token('space.100')} ${token('space.200')}`,
	position: 'relative',
	textAlign: 'left',
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		paddingTop: token('space.150'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		paddingBottom: token('space.150'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		backgroundColor: token('elevation.surface.hovered'),
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultExcerpt = styled.div({
	color: token('color.text.subtle'),
	font: token('font.body.small'),
	marginTop: token('space.025'),
	maxHeight: '28px',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultSpace = styled.div({
	color: token('color.text.subtle'),
	font: token('font.body.small'),
	marginTop: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchNoResultWrapper = styled.div({
	width: '100%',
	minHeight: '230px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		width: '61px',
		height: '82px',
		margin: `${token('space.400')} 0 ${token('space.150')} 0`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextWrapper = styled.div({
	margin: `0 ${token('space.500')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchLoadWrapper = styled.div({
	width: '100%',
	height: '230px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultMoreWrapper = styled.div({
	backgroundColor: token('elevation.surface.overlay'),
	maxHeight: '20px',
	padding: `${token('space.100')} ${token('space.200')} 10px ${token('space.200')}`,
	textAlign: 'left',
	position: 'sticky',
	bottom: 0,
	borderTop: `1px solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		backgroundColor: token('elevation.surface.overlay.hovered'),
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextContentBold = styled.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		fontWeight: token('font.weight.bold'),
	},
	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextContent = styled.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		display: 'block',
		margin: 0,
		padding: 0,
	},
	color: token('color.text'),
});
